import React, { lazy, Suspense } from 'react';
import Loading from '../loading';

const MediaLinkSetEdit = lazy(() => import('./edit/media_link_set_edit'));

class MediaLinkSetBlock extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    if (this.props.edit) {
      return (
        <Suspense fallback={<Loading/>}>
          <MediaLinkSetEdit {...this.props} />
        </Suspense>
      )
    } else {
      return null;
    }
  }
}

export default MediaLinkSetBlock
